<div class="draft-cover-box">
  <div class="row">
    <div class="col-md-12">
      <img src="assets/images/logo-white-yellow.svg" />
    </div>
    <div class="col-md-12">
      <h2>{{ reportDetails.title }}</h2>
    </div>
  </div>

  <div class="row">
    <div class="col-md-3 col-sm-6">
      <label class="label-text">Reference Number </label>
      <p class="p-text">{{ reportDetails.reference_number }}</p>
    </div>
    <div class="col-md-3 col-sm-6">
      <label class="label-text">Prepared by </label>
      <p class="p-text">
        {{ reportDetails.prepared_by?.first_name }}
        {{ reportDetails.prepared_by?.last_name }}
      </p>
    </div>
    <div class="col-md-5 col-sm-6">
      <label class="label-text">Prepared at </label>
      <p class="p-text">
        {{ reportDetails.prepared_at | dateTimeFormat }}
      </p>
    </div>
  </div>
</div>
