import { Component, Input } from '@angular/core';
import { AuditDraftReportDocumentDTO } from '../../../../../dto/audit-draft-report.dto';
import { DateTimeFormatPipe } from '../../../../../../../../core/pipes/date-time-format.pipe';

@Component({
  selector: 'app-draft-report-cover-page',
  standalone: true,
  imports: [DateTimeFormatPipe],
  templateUrl: './draft-report-cover-page.component.html',
  styleUrl: './draft-report-cover-page.component.scss',
})
export class DraftReportCoverPageComponent {
  @Input() reportDetails: AuditDraftReportDocumentDTO;
}
