import { Component, Input } from '@angular/core';
import { AuditChecklistFindingDetailsDTO } from '../../../../../dto/audit-checklist-finding.dto';
import { DateTimeFormatPipe } from '../../../../../../../../core/pipes/date-time-format.pipe';

@Component({
  selector: 'app-draft-report-finding-status',
  standalone: true,
  imports: [DateTimeFormatPipe],
  templateUrl: './draft-report-finding-status.component.html',
  styleUrl: './draft-report-finding-status.component.scss'
})
export class DraftReportFindingStatusComponent {
  @Input() draftFinding: AuditChecklistFindingDetailsDTO;

}
