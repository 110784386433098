<div class="row mt-3">
    @if(draftFinding.conversations.length == 0){
        <div class="col-md-12">
            <div class="widget-block widget-grey-bg text-center d-flex align-items-center justify-content-center ">
                <div>
                    <h4 class="text-center">No Department Response</h4>
                    <p>Check back soon for updates</p>
                        @if(isDepartmentReview() && reportType == 'external-department'){
                            <button (click)="openFindingAcceptForm(draftFinding)" type="button" class="btn btn-primary btn-theme">Accept Finding</button>
                            <button (click)="openFindingChallengeForm(draftFinding)" type="button" class="btn btn-outline-danger ms-2">Challenge</button>
                        }
                </div>
            </div>
        </div>
    }

    @if(draftFinding.conversations.length >0 && (isDepartmentReview() || isCompleted())){
        <div class="col-md-12 d-flex align-items-center justify-content-between">
            <h4>Latest Responses</h4> 
            <a href="javacript:void(0)" (click)="openFindingExternalConversationForm(draftFinding)" class="a-link">View All {{draftFinding.conversations.length}} Responses</a>
        </div>
        @if(draftFinding.latest_response){
        <div class="col-md-12">
            <div class="sm-widget-block ">
                <div>
                    <div class="d-flex ">
                        <h5 class="me-2 font-md d-flex align-items-center"> {{ draftFinding.latest_response.commented_user?.first_name + ' ' + draftFinding.latest_response.commented_user?.last_name }}</h5>
                        @if(draftFinding.latest_response.type == 'AC'){
                            <small>Auditor Comment</small>
                        }@else if(draftFinding.latest_response.type == 'DR'){
                            <small>Department Response</small>
                        }
                    </div>
                    <p class="mb-1">
                        <app-read-more [text]="draftFinding.latest_response.comment || ''" [maxLength]="maxLength"></app-read-more>
                    </p>
                
                </div>
            </div>
        </div>
        }
    } 

</div>


