import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuditDraftReportDocumentDTO } from '../../../../../dto/audit-draft-report.dto';
import { MasterListDTO } from '../../../../../../../../shared/dto/master.dto';
import { LabelDisplayComponent } from '../../../../../../../../shared/components/layout/details/label-display/label-display.component';

@Component({
  selector: 'app-draft-report-conclusion',
  standalone: true,
  imports: [LabelDisplayComponent],
  templateUrl: './draft-report-conclusion.component.html',
  styleUrl: './draft-report-conclusion.component.scss',
})
export class DraftReportConclusionComponent {
  maxLength: number = 250;
  @Input() reportDetails: AuditDraftReportDocumentDTO;
  @Input() draftReportStatus: MasterListDTO | null;
  @Input() reportType: string;
  @Output() openCommentPanelRequest = new EventEmitter<string>();


  requestToOpenCommentPanel(type: string) {
    this.openCommentPanelRequest.emit(type);
  }

}
