<div class="widget-block bg-light-grey">
  <div class="row">
    <div class="col-md-3">
      <app-draft-report-index [reportDetails]="reportDocumentDetails"></app-draft-report-index>
    </div>
    <div class="col-md-9 draft-report">
      <app-draft-report-cover-page [reportDetails]="reportDocumentDetails"></app-draft-report-cover-page>

      <div id="overview" class="draft-page">
       <app-draft-report-overview
        (openCommentPanelRequest)="openCommentPanel($event)"
        [draftReportStatus]="draftReportStatus"
        [reportDetails]="reportDocumentDetails"
        [reportType]="reportType">
       </app-draft-report-overview>

       @if(reportDocumentDetails.draft_findings){
        <div id="findings" class="row">
          <h4>Findings</h4>
          <div class="col-md-12">
            @for (draftFinding of reportDocumentDetails.draft_findings; track draftFinding){
              <app-draft-report-finding-item
                [draftFinding]="draftFinding"
                [draftReportStatus]="draftReportStatus"
                [reportType]="reportType"
                (openFindingFormRequest)="openFindingForm($event)"
                (openFindingExternalConversationFormRequest)="openFindingExternalConversationForm($event)"
                (openInternalConversationPanelRequest)="openFindingInternalConvesationPanel($event)"
                (openFindingAcceptFormRequest)="openFindingAcceptForm($event)"
                (openFindingChallengeFormRequest)="openFindingChallengeForm($event)"
                (openFindingActionPlanFormRequest)="openFindingActionPlanForm($event)"
                (findingDeleteRequest)="deleteFinding($event)"
                (deleteFindingActionPlanRequest)="deleteFindingActionPlan($event)">
              </app-draft-report-finding-item>
            }
          </div>
        </div>
        }
        <app-draft-report-conclusion 
          [draftReportStatus]="draftReportStatus" 
          [reportDetails]="reportDocumentDetails" 
          (openCommentPanelRequest)="openCommentPanel($event)"
          [reportType]="reportType">
        </app-draft-report-conclusion>
      </div>
    </div>
  </div>
</div>


<!-- //converation component panel -->
