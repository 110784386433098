  <div id="conclusion" class="col-md-12">
    <h4>Conclusion</h4>
    @if(reportType == 'internal-department'){
      <div class="comment-section">
        <a href="javascript:void(0)" (click)="requestToOpenCommentPanel('conclusion')" class="a-link">Comments</a>
      </div>
    }
  </div>
  <div class="widget-block p-4" style="background: #f4f9f5">
    <div class="row">
      <div class="col-md-6">
        <app-label-display
          [type]="'user'"
          [label]="'Concluded by'"
          [user]="reportDetails.prepared_by">
        </app-label-display>
        
      </div>
      <div class="col-md-6">
        <app-label-display
          [type]="'datetime'"
          [label]="'Concluded On'"
          [value]="reportDetails.prepared_at">
        </app-label-display>
      
      </div>

      <div class="col-md-12">
        <app-label-display
        [type]="'para'"
        [label]="''"
        [value]="reportDetails.conclusion">
      </app-label-display>
      </div>
    </div>
  </div>
