import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuditChecklistFindingDetailsDTO } from '../../../../dto/audit-checklist-finding.dto';
import { SideFormComponent } from '../../../../../../../shared/components/forms/side-form/side-form.component';
import { SideFormModalComponent } from '../../../../../../../shared/components/modals/side-form-modal/side-form-modal.component';
import { ReadMoreComponent } from '../../../../../../../shared/components/layout/details/read-more/read-more.component';
import { DiscussionPanelLayoutComponent } from '../../../../../../../shared/components/layout/discussion-panel/discussion-panel-layout/discussion-panel-layout.component';
import { MasterListDTO } from '../../../../../../../shared/dto/master.dto';

@Component({
  selector: 'app-audit-details-report-details-conversations',
  standalone: true,
  imports: [
    SideFormComponent,
    SideFormModalComponent,
    ReadMoreComponent,
    DiscussionPanelLayoutComponent
  ],
  templateUrl: './audit-details-report-details-conversations.component.html',
  styleUrl: './audit-details-report-details-conversations.component.scss'
})
export class AuditDetailsReportDetailsConversationsComponent {
  @Input() draftFinding: AuditChecklistFindingDetailsDTO;
  @Input() draftReportStatus: MasterListDTO | null;
  @Input() reportType: string;

  maxLength: number = 250;


  @Output() openFindingExternalConversationFormRequest = new EventEmitter<AuditChecklistFindingDetailsDTO>();
  @Output() openFindingAcceptFormRequest = new EventEmitter<AuditChecklistFindingDetailsDTO>();
  @Output() openFindingChallengeFormRequest = new EventEmitter<AuditChecklistFindingDetailsDTO>();

  openFindingExternalConversationForm(item: AuditChecklistFindingDetailsDTO) {
    this.openFindingExternalConversationFormRequest.emit(item);
  }

  openFindingAcceptForm(item: AuditChecklistFindingDetailsDTO) {
    this.openFindingAcceptFormRequest.emit(item);
  }
  openFindingChallengeForm(item: AuditChecklistFindingDetailsDTO) {
    this.openFindingChallengeFormRequest.emit(item);
  }

  isDraft() {
    return this.draftReportStatus?.type === 'draft';
  }

  isInternalReview() {
    return this.draftReportStatus?.type === 'in-review';
  }

  isReverted() {
    return this.draftReportStatus?.type === 'revert';
  }

  isApproved() {
    return this.draftReportStatus?.type === 'approved';
  }

  isDepartmentReview() {
    return this.draftReportStatus?.type === 'in-department-review';
  }

  isCompleted() {
    return this.draftReportStatus?.type === 'completed';
  }


}
