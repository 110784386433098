<div class="row">
  <div class="col-md-12">
    <h4>Audit Report Overview</h4>
    @if(reportType == 'internal-department'){
      <div class="comment-section">
        <a href="javascript:void(0)" (click)="requestToOpenCommentPanel('executive-summary')" class="a-link">Comments</a>
      </div>
    }
    <h6 class="mt-3">Executive Summary</h6>
    <p>{{ reportDetails.executive_summary }}</p>
  </div>
</div>
<div class="row">
  <div class="col-md-6">
    <div class="sm-widget-block align-items-start">
      <img src="assets/images/audit-icons/icon-audit-lead.svg" />
      <div class="ms-2">
        <label class="label-text">Lead Auditor </label>
        <p class="p-text">
          {{ reportDetails.team_lead?.first_name }}
          {{ reportDetails.team_lead?.last_name }}
        </p>
      </div>
    </div>
  </div>
  <div class="col-md-6">
    <div class="sm-widget-block align-items-start">
      <img src="assets/images/audit-icons/icon-audit-date.svg" />
      <div class="ms-2">
        <label class="label-text">Report Date </label>
        <p class="p-text">
          {{ reportDetails.report_date | dateTimeFormat }}
        </p>
      </div>
    </div>
  </div>
  <div class="col-md-12">
    <div class="sm-widget-block align-items-start">
      <img src="assets/images/audit-icons/icon-auditor.svg" />
      <div class="ms-2">
        <label class="label-text">Auditors </label>
        @for (auditor of reportDetails.auditors; track auditor){
        <p class="p-text">
          {{ auditor.first_name + " " + auditor.last_name }}
        </p>
        }
      </div>
    </div>
  </div>

  <!-- <div class="col-md-6">
            <div class="sm-widget-block align-items-start">
                <img src="assets/images/audit-icons/auditee-icon.svg">
                <div class="ms-2">
                    <label class="label-text">Auditee </label><p class="p-text">Ahmed Khan   </p>
                </div>
            </div>
        </div> -->

</div>
