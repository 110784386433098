import { Component, Input } from '@angular/core';
import { TruncatePipe } from '../../../../../../../../core/pipes/truncate.pipe';
import { AuditDraftReportDocumentDTO } from '../../../../../dto/audit-draft-report.dto';

@Component({
  selector: 'app-draft-report-index',
  standalone: true,
  imports: [TruncatePipe],
  templateUrl: './draft-report-index.component.html',
  styleUrl: './draft-report-index.component.scss',
})
export class DraftReportIndexComponent {
  @Input() reportDetails: AuditDraftReportDocumentDTO | null;

  scrollTo(section: string): void {
    const element = document.getElementById(section);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }
}
