import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuditDraftReportDocumentDTO } from '../../../../../dto/audit-draft-report.dto';
import { DraftReportIndexComponent } from '../draft-report-index/draft-report-index.component';
import { DraftReportCoverPageComponent } from '../draft-report-cover-page/draft-report-cover-page.component';
import { DraftReportOverviewComponent } from '../draft-report-overview/draft-report-overview.component';
import { DraftReportFindingItemComponent } from '../draft-report-finding-item/draft-report-finding-item.component';
import { DraftReportConclusionComponent } from '../draft-report-conclusion/draft-report-conclusion.component';
import { AuditChecklistFindingDetailsDTO } from '../../../../../dto/audit-checklist-finding.dto';
import { MasterListDTO } from '../../../../../../../../shared/dto/master.dto';
import { AuditInsightDraftReportFindingActionPlanDetailsDTO } from '../../../../../../../audit-insight/draft-report/dto/audit-insight-draft-report-finding-action.dto';

@Component({
  selector: 'app-draft-report-page',
  standalone: true,
  imports: [
    DraftReportIndexComponent,
    DraftReportCoverPageComponent,
    DraftReportOverviewComponent,
    DraftReportFindingItemComponent,
    DraftReportConclusionComponent,
  ],
  templateUrl: './draft-report-page.component.html',
  styleUrl: './draft-report-page.component.scss',
})
export class DraftReportPageComponent {
  @Input() reportDocumentDetails: AuditDraftReportDocumentDTO;
  @Input() isFindingEditButton: boolean = false;
  @Input() isFindingDeleteButton: boolean = false;
  @Input() draftReportStatus: MasterListDTO | null;
  @Input() reportType: string;
  @Output() findingEditRequest = new EventEmitter<any>();
  @Output() findingDeleteRequest = new EventEmitter<any>();
  @Output() conversationAddRequest = new EventEmitter<any>();
  @Output() conversationEditRequest = new EventEmitter<any>();
  @Output() actionPlanAddRequest = new EventEmitter<any>();

  @Output() openFindingFormRequest =
    new EventEmitter<AuditChecklistFindingDetailsDTO>();
  @Output() openFindingInternalConversationPanelRequest =
    new EventEmitter<AuditChecklistFindingDetailsDTO>();
  @Output() deleteFindingRequest = new EventEmitter<number>();

  @Output() openCommentPanelRequest = new EventEmitter<string>();
  @Output() closeCommentPanelRequest = new EventEmitter<string>();

  @Output() openFindingExternalConversationFormRequest = new EventEmitter<AuditChecklistFindingDetailsDTO>();
  @Output() openFindingAcceptFormRequest = new EventEmitter<AuditChecklistFindingDetailsDTO>();
  @Output() openFindingChallengeFormRequest = new EventEmitter<AuditChecklistFindingDetailsDTO>();
  @Output() openFindingActionPlanFormRequest = new EventEmitter<{ item: AuditChecklistFindingDetailsDTO, actionPlan?: AuditInsightDraftReportFindingActionPlanDetailsDTO }>();
  @Output() deleteFindingActionPlanRequest = new EventEmitter<AuditInsightDraftReportFindingActionPlanDetailsDTO>();
  @Output() openReportConclusionCommentPanelRequest = new EventEmitter<string>();


  maxLength: number = 250;

  scrollTo(section: string): void {
    const element = document.getElementById(section);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }

  openFindingForm(item: AuditChecklistFindingDetailsDTO) {
    this.openFindingFormRequest.emit(item);
  }

  openFindingInternalConvesationPanel(item: AuditChecklistFindingDetailsDTO) {
    this.openFindingInternalConversationPanelRequest.emit(item);
  }

  deleteFinding(findingId: number) {
    this.deleteFindingRequest.emit(findingId);
  }

  openCommentPanel(type: string) {
    this.openCommentPanelRequest.emit(type);
  }

  closeCommentPanel(type: string) {
    this.closeCommentPanelRequest.emit(type);
  }

  openFindingExternalConversationForm(item: AuditChecklistFindingDetailsDTO) {
    this.openFindingExternalConversationFormRequest.emit(item);
  }
  
  openFindingAcceptForm(item: AuditChecklistFindingDetailsDTO) {
    this.openFindingAcceptFormRequest.emit(item);
  }
  
  openFindingChallengeForm(item: AuditChecklistFindingDetailsDTO) {
    this.openFindingChallengeFormRequest.emit(item);
  }

  openFindingActionPlanForm(event: { item: AuditChecklistFindingDetailsDTO, actionPlan?: AuditInsightDraftReportFindingActionPlanDetailsDTO }) {
    this.openFindingActionPlanFormRequest.emit(event);
  }

  deleteFindingActionPlan(item: AuditInsightDraftReportFindingActionPlanDetailsDTO) {
    this.deleteFindingActionPlanRequest.emit(item);
  }

  openReportConclusionCommentPanel(item: string) {
    this.openReportConclusionCommentPanelRequest.emit(item);
  }
 
}
