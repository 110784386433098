@if(draftFinding.accepted_by){
<div class="row">
  <div class="col-md-12">
    <h4> Action Plans
      @if(isDepartmentReview() && reportType == 'external-department'){
        <a class="btn-add-more-plus" (click)="openFindingActionPlanForm(draftFinding)">Add</a> 
      }
    </h4> 
  </div>
    
  
    @if(draftFinding.action_plans.length>0) {
        <div class="col-md-12">
            @for(actionPlan of draftFinding.action_plans; track actionPlan) {
                <app-accordion-item [title]="actionPlan.title" [itemId]="'draftFinding_'+draftFinding.id+'_action_plan_'+actionPlan.id" [open]="false">
                    @if(reportType == 'external-department'){
                    <div class="col-md-12">
                        <div class="d-flex justify-content-end">
                          <div class="table-item-action-dropdown">
                            <button type="button" data-bs-toggle="dropdown" class="action-button" id="action1" aria-expanded="false">
                              <img src="assets/images/icons/icon-more.svg" alt="More Actions">
                            </button>
                            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="action1">
                      
                              @if (actionPlan.id){
                                <li>
                                  <a class="dropdown-item" (click)="openFindingActionPlanForm(draftFinding, actionPlan)">
                                    <img src="assets/images/icons/icon-edit.svg" alt="Edit Finding"> Edit Action Plan
                                  </a>
                                </li>
                                <li>
                                  <a class="dropdown-item" (click)="deleteFindingActionPlan(actionPlan)">
                                    <img src="assets/images/icons/icon-delete.svg" alt="Delete Finding"> Delete Action Plan
                                  </a>
                                </li>
                              }
                            </ul>
                          </div>
                        </div>
                      </div>
                    }
                                                                     
                    <div class="col-md-6 col-sm-12">
                        <app-label-display
                            [type]="'datetime'"
                            [label]="'Target date'"
                            [value]="actionPlan.target_date">
                        </app-label-display>
                       
                    </div>
                    <div class="col-md-6 col-sm-12">
                        <app-label-display
                            [type]="'user'"
                            [label]="'Responsibility'"
                            [user]="actionPlan.responsible_user">
                      </app-label-display>
                    </div>
                    <div class="col-md-12 col-sm-12">
                        <app-label-display
                            [type]="'para'"
                            [label]="'Description'"
                            [value]="actionPlan.description">
                        </app-label-display>
                       
                    </div>

                </app-accordion-item>
            }
        </div>
    } @else{
         
        <div class="col-md-12">
            <div class="widget-block widget-grey-bg text-center d-flex align-items-center justify-content-center ">
                <div>
                    <h4 class="text-center">No Action Plans</h4>
                    <p>Check back soon for updates</p>
                    @if(isDepartmentReview() && reportType == 'external-department' ){
                      <button (click)="openFindingActionPlanForm(draftFinding)" type="button" class="btn btn-primary btn-theme">Add Action Plan</button>
                    }
                </div>
            </div>
        </div>
    }
</div>
}
