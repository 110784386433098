<div class="page-title-box">
  <h4>Report Content</h4>
  <ul class="affix" data-spy="affix" data-offset-top="197">
    <li>
      <a href="javascript:void(0)" (click)="scrollTo('overview')">Oveview</a>
    </li>
    <li>
      <a href="javascript:void(0)" (click)="scrollTo('findings')">Findings</a>
    </li>
    @for (draftFinding of reportDetails?.draft_findings; track draftFinding){
    <li class="ms-3">
      <a
        href="javascript:void(0)"
        (click)="scrollTo('draftFinding_' + draftFinding.id)">{{ draftFinding.title | truncate : 50 }}
      </a>
    </li>
    }

    <li>
      <a href="javascript:void(0)" (click)="scrollTo('conclusion')"
        >Conclusion
      </a>
    </li>
  </ul>
</div>
