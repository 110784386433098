import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AccordionItemComponent } from '../../../../../../../shared/components/accordions/accordion-item/accordion-item.component';
import { AuditChecklistFindingDetailsDTO } from '../../../../dto/audit-checklist-finding.dto';
import { DateTimeFormatPipe } from '../../../../../../../core/pipes/date-time-format.pipe';
import { LabelDisplayComponent } from '../../../../../../../shared/components/layout/details/label-display/label-display.component';
import { MasterListDTO } from '../../../../../../../shared/dto/master.dto';
import { AuditInsightDraftReportFindingActionPlanDetailsDTO, AuditInsightDraftReportFindingActionPlanFormDTO } from '../../../../../../audit-insight/draft-report/dto/audit-insight-draft-report-finding-action.dto';

@Component({
  selector: 'app-audit-details-report-details-action-plan',
  standalone: true,
  imports: [
    AccordionItemComponent,
    DateTimeFormatPipe,
    LabelDisplayComponent
  ],
  templateUrl: './audit-details-report-details-action-plan.component.html',
  styleUrl: './audit-details-report-details-action-plan.component.scss'
})
export class AuditDetailsReportDetailsActionPlanComponent {
  @Input() draftFinding: AuditChecklistFindingDetailsDTO;
  @Input() draftReportStatus: MasterListDTO | null;
  @Input() reportType: string;

  @Output() openFindingActionPlanFormRequest = new EventEmitter<{ item: AuditChecklistFindingDetailsDTO, actionPlan?: AuditInsightDraftReportFindingActionPlanDetailsDTO }>();
  @Output() deleteFindingActionPlanRequest = new EventEmitter<AuditInsightDraftReportFindingActionPlanDetailsDTO>();


  openFindingActionPlanForm(item: AuditChecklistFindingDetailsDTO, actionPlan?: AuditInsightDraftReportFindingActionPlanDetailsDTO) {
    this.openFindingActionPlanFormRequest.emit({ item, actionPlan });
  }
  deleteFindingActionPlan(item: AuditInsightDraftReportFindingActionPlanDetailsDTO) {
    this.deleteFindingActionPlanRequest.emit(item);
  }

  isDraft() {
    return this.draftReportStatus?.type === 'draft';
  }

  isInternalReview() {
    return this.draftReportStatus?.type === 'in-review';
  }

  isReverted() {
    return this.draftReportStatus?.type === 'revert';
  }

  isApproved() {
    return this.draftReportStatus?.type === 'approved';
  }

  isDepartmentReview() {
    return this.draftReportStatus?.type === 'in-department-review';
  }

  isCompleted() {
    return this.draftReportStatus?.type === 'completed';
  }
}
