import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AuditChecklistFindingDetailsDTO } from '../../../../../dto/audit-checklist-finding.dto';
import { AccordionItemComponent } from '../../../../../../../../shared/components/accordions/accordion-item/accordion-item.component';
import { LabelDisplayComponent } from '../../../../../../../../shared/components/layout/details/label-display/label-display.component';
import { ReadMoreComponent } from '../../../../../../../../shared/components/layout/details/read-more/read-more.component';
import { AuditDetailsReportDetailsConversationsComponent } from '../../audit-details-report-details-conversations/audit-details-report-details-conversations.component';
import { AuditDetailsReportDetailsActionPlanComponent } from '../../audit-details-report-details-action-plan/audit-details-report-details-action-plan.component';
import { TruncatePipe } from '../../../../../../../../core/pipes/truncate.pipe';
import { FileSizePipe } from '../../../../../../../../core/pipes/file-size.pipe';
import { DraftReportFindingStatusComponent } from '../draft-report-finding-status/draft-report-finding-status.component';
import { MasterListDTO } from '../../../../../../../../shared/dto/master.dto';
import { AuditInsightDraftReportFindingActionPlanDetailsDTO } from '../../../../../../../audit-insight/draft-report/dto/audit-insight-draft-report-finding-action.dto';


@Component({
  selector: 'app-draft-report-finding-item',
  standalone: true,
  imports: [
    AccordionItemComponent,
    LabelDisplayComponent,
    ReadMoreComponent,
    AuditDetailsReportDetailsConversationsComponent,
    AuditDetailsReportDetailsActionPlanComponent,
    TruncatePipe,
    FileSizePipe,
    DraftReportFindingStatusComponent,
  ],
  templateUrl: './draft-report-finding-item.component.html',
  styleUrl: './draft-report-finding-item.component.scss',
})
export class DraftReportFindingItemComponent {
  @Input() draftFinding: AuditChecklistFindingDetailsDTO;
  @Input() draftReportStatus: MasterListDTO | null;
  @Input() reportType: string;


  maxLength: number = 100;
  isFindingEditButton: boolean = false;

  @Output() openFindingFormRequest =
    new EventEmitter<AuditChecklistFindingDetailsDTO>();
  @Output() openInternalConversationPanelRequest =
    new EventEmitter<AuditChecklistFindingDetailsDTO>();
  @Output() findingDeleteRequest = new EventEmitter<number>();

  @Output() openFindingExternalConversationFormRequest = new EventEmitter<AuditChecklistFindingDetailsDTO>();
  @Output() openFindingAcceptFormRequest = new EventEmitter<AuditChecklistFindingDetailsDTO>();
  @Output() openFindingChallengeFormRequest = new EventEmitter<AuditChecklistFindingDetailsDTO>();
  @Output() openFindingActionPlanFormRequest = new EventEmitter<{ item: AuditChecklistFindingDetailsDTO, actionPlan?: AuditInsightDraftReportFindingActionPlanDetailsDTO }>();
  @Output() deleteFindingActionPlanRequest = new EventEmitter<AuditInsightDraftReportFindingActionPlanDetailsDTO>();


  constructor(
  ) {}

  openEditFindingForm(item: AuditChecklistFindingDetailsDTO) {
    this.openFindingFormRequest.emit(item);
  }

  openFindingConversationPanel(item: AuditChecklistFindingDetailsDTO) {
    this.openInternalConversationPanelRequest.emit(item);
  }

  deleteFinding(findingId: number) {
    this.findingDeleteRequest.emit(findingId);
  }

  openFindingExternalConversationForm(item: AuditChecklistFindingDetailsDTO) {
    this.openFindingExternalConversationFormRequest.emit(item);
  }

  openFindingAcceptForm(item: AuditChecklistFindingDetailsDTO) {
    this.openFindingAcceptFormRequest.emit(item);
  }
  
  openFindingChallengeForm(item: AuditChecklistFindingDetailsDTO) {
    this.openFindingChallengeFormRequest.emit(item);
  }


  openFindingActionPlanForm(event: { item: AuditChecklistFindingDetailsDTO, actionPlan?: AuditInsightDraftReportFindingActionPlanDetailsDTO }) {
    this.openFindingActionPlanFormRequest.emit(event);
  }

  deleteFindingActionPlan(item: AuditInsightDraftReportFindingActionPlanDetailsDTO) {
    this.deleteFindingActionPlanRequest.emit(item);
  }

  isDraft() {
    return this.draftReportStatus?.type === 'draft';
  }

  isInternalReview() {
    return this.draftReportStatus?.type === 'in-review';
  }

  isReverted() {
    return this.draftReportStatus?.type === 'revert';
  }

  isApproved() {
    return this.draftReportStatus?.type === 'approved';
  }

  isDepartmentReview() {
    return this.draftReportStatus?.type === 'in-department-review';
  }

  isCompleted() {
    return this.draftReportStatus?.type === 'completed';
  }

  handleAccordionClickAction(draftFinding: AuditChecklistFindingDetailsDTO){
    draftFinding.is_open = !draftFinding.is_open
  }
}
