
@if(draftFinding.accepted_by){
  <div class="alert-success d-flex mb-3">
    <img
      class="me-1"
      src="assets/images/audit-icons/icon-tick-success-circle.svg"
      alt="Accepted"
    />Accepted by {{draftFinding.accepted_by.first_name +' '+ draftFinding.accepted_by.last_name}} on {{draftFinding.accepted_at | dateTimeFormat}}
    <!-- <a class="a-link text-success" href="javacript:void(0)"
      >{{draftFinding.conversations.length}} responses</a> -->
  </div>
}@else if(draftFinding.challenged_by){
  <div class="alert-danger d-flex mb-3">
    <img
      class="me-1"
      src="assets/images/audit-icons/icon-flag.svg"
      alt="Challenge"
      alt="Overview Icon"
    />Challenged by {{draftFinding.challenged_by.first_name +' '+ draftFinding.challenged_by.last_name}} on {{draftFinding.challenged_at | dateTimeFormat}}
    <!-- <a class="a-link text-danger" href="javacript:void(0)"
      >{{draftFinding.conversations.length}} responses</a> -->
  </div>
}

